import styled, { css } from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: center;
  font-size: 2.2em;
  font-weight: bold;
  color: #D8232A;
  letter-spacing: 0;
`;

export const PriceView = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 1em;
  padding-bottom: 1.5em;
  height: 16em;
  position: relative;

  .price-description {
    text-align: center;
    span {
      font-size: 1.5em;
      font-weight: bold;
    }
    
    sub {
      font-weight: normal;
      font-size: 1.1em;
    }
    
    s {
      margin-left: 1em;   
      font-size: 1.1em;
      color: gray;
    }
  }
  
  .discount-description {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1.6em;
  }
  
  .timeSpan {
    justify-content: center;
    display: flex;
    margin-top: 0;
    font-size: 1.8em;
    line-height: 0;
    color: #283278;
  }

  .mainPrice {
    flex-direction: column;
    padding-top: 0.7em;
    color: #283278;

    .value {
      font-size: 7em;
    }
  }

  .currencyStyle {
    font-size: 3.5em;
    font-weight: 100;
  }

  .strike {
    position: relative;
    color: #283278;
  }

  .strike:before {
    position: absolute;
    content: "";
    left: -1.2em;
    top: 39%;
    right: 1.4em;
    border-top: 0.1em solid;
    border-color: #283278;

    transform:rotate(-14deg);
  }

  .priceSummary {
    position: absolute;
    top: 6.4em;
    left: 13em;
    display: flex;
    flex-direction: row;
    font-size: 1.4em;
    & :first-child {
      padding-right: 2em;
      color: #283278;
      border-color: #283278;
    }
  }
`;

type ContainerProps = {
  recommended?: boolean;
};

export const Container = styled.div`
  padding-top: 4em;
  padding-bottom: 3.5em;
  width: 30em;
  min-width: 30em;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  
  
  ${(props: ContainerProps) => props.recommended && css`
    outline: 1px solid #283278;
  `}
  
  -webkit-box-shadow: 0.1em 0.1em 1.1em -0.2em rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0.1em 0.1em 1.1em -0.2em rgba(0, 0, 0, 0.25);
  box-shadow: 0.1em 0.1em 1.1em -0.2em rgba(0, 0, 0, 0.25);

  &:hover {
    -webkit-box-shadow: 0 0.6em 2.4em 0.2em rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0 0.6em 2.4em 0.2em rgba(0, 0, 0, 0.35);
    box-shadow: 0 0.6em 2.4em 0.2em rgba(0, 0, 0, 0.35);
    backface-visibility: hidden;
  }
  &.topPadding {
    padding-top: 2.9em;
  }

  &.disabled {
    ${PriceView} *,
    ${Header} {
      color:  rgb(156, 166, 185);
    }
  }

  &.selected {
    -webkit-box-shadow: 0 0.6em 2.4em 0.2em rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0 0.6em 2.4em 0.2em rgba(0, 0, 0, 0.35);
    box-shadow: 0 0.6em 2.4em 0.2em rgba(0, 0, 0, 0.35);
    backface-visibility: hidden;

    outline: 1px solid #283278;
  }
`;

export const CheckList = styled.div`
  ul {
    list-style: none;
    padding: 0.6em 1.5em 1.5em 1.5em;
  }

  li {
    padding-top: 0.6em;
    line-height: 2em;
  }

  font-size: 1.25em;
`;

export const ListItem = styled.li`
  & :first-child {
    display: inline-block;
  }

  img {
    display: inline-block;
    height: 1.3em;
    width: 2em;
  }

   &.disabled {
    color:  rgb(156, 166, 185);
    path {
      fill: rgb(198, 206, 220);
    }
  }

  &.futureOffer {
      color:  rgb(156, 166, 185);
      text-align: center;        
    }
`;

export const Footer = styled.div`
  margin-top: auto;
  justify-content: center;
  display: flex;
  position: relative;
`;

export const CurrentPlanMessage = styled.span`
  font-size: 1.4em;
  color: #273178;
  font-weight: bold;
  padding-top: 1.5em;
  padding-bottom: 1.7em;

  &.selectedPlanAllowed {
    position: absolute;
    top: 4em;
  }
`;

import classNames from 'classnames'; // todo ##
import * as React from 'react';
import styled from 'styled-components';

const noop = () => {
};

export const buttonShadow = () => `
  box-shadow: -0.1em 0.7em 3.6em -1.2em rgba(0, 0, 0, 0.6);
`;

export const buttonShadowActive = () => `
  box-shadow: -0.1em 0.7em 3.6em -1.2em rgba(0, 0, 0, 0.3);
`;

export const buttonShadowDisabled = () => `
  box-shadow: -0.1em 0.7em 3.6em -1.2em rgba(0, 0, 0, 0.3);
`;

export const resetButtonStyle = () => `
  box-sizing: content-box;
  color: #fff;
  background: none;
  border: 0;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  user-select: none;
  outline: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`;

const ButtonElement = styled.button<{ isSmall?: boolean }>`
  ${resetButtonStyle()}
  transition: all 0.3s;

  &:not(.disabled):not(.flat) {
    ${buttonShadow()}
  }

  .disabled:not(.flat) {
    ${buttonShadowDisabled()}
  }

  &.noWrap {
    white-space: nowrap;
  }

  &.primary {
    background-color: ${'#D8232A'};

    &:hover:not(.disabled) {
      background-color: ${'#c72027'};
    }

    &.disabled {
      background-color: ${'#906667'};
    }
  }

  &:active {
    transform: translateY(0.1em);
    ${buttonShadowActive()}
  }
  
  position: relative;

  cursor: pointer;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  font-size: 1.1em;

  height: 4em;
  border-radius: 0.8em;
  margin: 0.5em;
  padding: 0 1em;
  min-width: 10em;

  &.withSpinner {
    position: relative;
    min-width: 7em;
    padding-left: 2.5em;
    padding-right: 2.5em;
  }

  .spinnerContainer {
    position: absolute;
    top: 50%;
    right: 0.6em;
    transform: translateY(-50%) scale(0.8);
  }
`;
type ButtonTheme = 'primary' | 'secondary' | 'brandColors' | 'pemium';

interface ButtonProps {
  onClick?(params: any): any;

  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled?: boolean;
  withSpinner?: boolean;
  loading?: boolean;
  theme?: ButtonTheme;
  style?: React.CSSProperties;
  isSmall?: boolean;
  className?: string;
  'data-testid'?: string;
  'aria-label'?: string;
}

const setDefault = (theme?: ButtonTheme) => {
  return (theme !== 'primary' && theme !== 'secondary') ? 'default' : undefined;
};

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    className, children, type, theme, disabled, onClick, loading, isSmall, withSpinner = false,
  } = props;

  const buttonClassName = classNames(theme, setDefault(theme), {
    disabled,
    withSpinner,
  }, className);

  return (
    <ButtonElement
      style={props.style}
      className={buttonClassName}
      type={type}
      disabled={disabled}
      onClick={(!loading && !disabled && onClick) ? onClick : noop}
      data-testid={props['data-testid']}
      isSmall={isSmall}
      aria-label={props['aria-label']}
    >
      {children}
    </ButtonElement>
  );
};

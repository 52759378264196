import styled from 'styled-components';

export const PlansContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  &  > * {
    margin: 1em 0.5em;
  }
`;
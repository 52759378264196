import * as React from 'react';
import { Button } from '../Button/Button';
import { CheckList, Container, Footer, Header, ListItem, PriceView } from './SuiteView-styled';
import classnames from 'classnames';  // todo ##

const arrow = require('./assets/arrow-list-point.svg');

const LogoArrowSvg = () => <img src={arrow} alt={"bullet point"}/>;

type Props = {
  recommended?: boolean;
  planData: {
    name: string;
    price: React.ReactNode;
    priceDescription: React.ReactNode;
    discountDescription: React.ReactNode;
    features: { label: string; future?: boolean }[];
  };
};

export class SuiteView extends React.Component<Props> {

  render() {
    const {
      planData: {
        name, price, priceDescription, discountDescription, features,
      },
    } = this.props;

    return (
      <a href="https://app.fakturuj.to/">
        {!this.props.recommended && (
          <div className="h-16"/>
        )}
        <Container recommended={this.props.recommended}>
          {this.props.recommended && (
            <div className="h-16 uppercase">
              <p className="text-center text-lg">Najpopularniejszy</p>
            </div>
          )}
          <Header>
            {name}
          </Header>
          <PriceView>
            <div className="mainPrice">
              <span className="value">{price}</span>
              <div className="price-description">
                {priceDescription}
              </div>
              <div className="discount-description">
                {discountDescription}
              </div>
            </div>
          </PriceView>
          <CheckList>
            <ul>
              {features.map((feature, idx) => (
                <ListItem
                  key={idx}
                  className={classnames({
                    futureOffer: feature.future,
                  })}
                  style={{
                    visibility: feature.label === '' ? 'hidden' : 'visible',
                  }}
                >
                  {!feature.future && <LogoArrowSvg/>}
                  {feature.label}
                </ListItem>
              ))}
            </ul>
          </CheckList>
          <Footer>

            <Button
              theme={'primary'}
            >
              WYBIERAM
            </Button>
          </Footer>
        </Container>
      </a>
    );
  }
}
